export const YOUTUBE_URL_REGEX =
  /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)([a-zA-Z0-9\-_])?/gi;
export const YOUTUBE_VIDEO_ID_REGEX =
  /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
const SPECIAL_CHARATERS_REGEX = /[\\^$.*+?()[\]{}|]/g;

export const escapeRegExp = (value: string) =>
  SPECIAL_CHARATERS_REGEX.test(value)
    ? value.replace(SPECIAL_CHARATERS_REGEX, '\\$&')
    : value;

export const getCouponRegex = (coupon: string) => {
  const escapedCoupon = escapeRegExp(coupon);
  return new RegExp(`${escapedCoupon}|{{([^{}]*)}}`, 'gi');
};

export const getDealsAndCouponsAsPath = (asPath: string) => {
  const regex = /^\/(d|cupons-de-descontos)\b/;
  const match = asPath.match(regex);

  if (match) {
    const basePath = match[0];
    return basePath;
  }

  return asPath;
};

export const CLEAR_ERROR_REGEX = /.+?(?=:)/;

export const removeQueryWhiteSpaces = (query: string) => {
  const transformedQuery = query
    .replace(/#.*\n/g, '')
    .replace(/[\s|,]*\n+[\s|,]*/g, ' ')
    .replace(/:\s/g, ':')
    .replace(/,\s/g, ',')
    .replace(/\)\s\{/g, '){')
    .replace(/\}\s/g, '}')
    .replace(/\{\s/g, '{')
    .replace(/\s\}/g, '}')
    .replace(/\s\{/g, '{')
    .replace(/\)\s/g, ')')
    .replace(/\(\s/g, '(')
    .replace(/\s\)/g, ')')
    .replace(/\s\(/g, '(')
    .replace(/=\s/g, '=')
    .replace(/\s=/g, '=')
    .replace(/@\s/g, '@')
    .replace(/\s@/g, '@')
    .replace(/\s\$/g, '$')
    .replace(/\s\./g, '.')
    .trim();
  return transformedQuery;
};

export const stripHtmlTags = (content: string) =>
  content.replace(/<[^>]*>/g, '');

export const onlyParagraphAndLineBreakTags = /^(<p><\/p>|<br\s*\/?>)*$/;

export const lettersOnlyRegex = /^[A-Za-z\s]+$/;
